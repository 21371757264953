


















































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { paciente } from "@/shared/dtos/paciente-dto";
import { RouterNames } from "@/router/routernames";
import pacienteModule from "@/store/modules/paciente-module";
import { ssmMessageService } from "@/shared/services/message-service";
import { UtilsPaciente } from "@/utils/utils-paciente";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
import etiquetaModule from "@/store/modules/etiqueta-module";
import paciente_conf_emailModule from "@/store/modules/paciente_conf_email-module";
import hist_clinicoModule from "@/store/modules/historial_clinico/hist_clinico-module";
import { etiqueta_paciente } from "@/shared/dtos/etiqueta_paciente";
import { hist_clinico } from "@/shared/dtos/Historial_clinico/hist_clinico";
import { buttonOptions } from "@/components/Buttons/buttonOptions";
import { UtilsNotify } from "@/utils/utils-notify";
@Component({
  $_veeValidate: { validator: "new" },
  components: {
    PacienteFichaOpciones: () => import("./paciente-ficha-opciones.vue"),
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    AddCitaPaciente: () => import("@/views/calendario/AddCitaPaciente.vue"),
    EtiquetasCombo: () =>
      import("@/components/ComboEtiquetas/EtiquetasCombo.vue"),
    HistClinicoCombo: () =>
      import("@/components/ComboEtiquetas/HistClinicoCombo.vue"),
  },
})
export default class PacienteFormulario extends Vue {
  public paciente: paciente = new paciente();
  public AddCitaShow: boolean = false;
  public fecha_cita: Date = new Date();
  public etiquetas_paciente: any[] = [];
  /*model hist clinico*/
  public hist_clinicos_diagnosticos: hist_clinico[] = [];
  public hist_clinicos_vacunas: hist_clinico[] = [];
  public hist_clinicos_medicamentos: hist_clinico[] = [];
  public hist_clinicos_cirujias: hist_clinico[] = [];
  public hist_clinicos_otros: hist_clinico[] = [];
  public numVacunas: number = 1;
  public numDiagnosticos: number = 2;
  public numMedicamentos: number = 3;
  public numCirugias: number = 4;
  public numOtros: number = 5;
  public tiene_email: boolean = false;
  public recordatorio_cita: boolean = true;
  public show_dialog_more_options: boolean = false;
  public show_dialog_config_app: boolean = false;

  /*
   * 1 Vacunas
   * 2 Diagnosticos
   * 3 Medicamentos
   * 4 Cirugias
   * 5 Otros
   */
  /*model hist clinico*/
  /**/

  public async created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id)) {
      this.paciente.numero = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id)
      );
      await pacienteModule.getpaciente(this.paciente).then(() => {
        this.tiene_email = !UtilsString.IsNullOrWhiteSpace(
          UtilsString.ValueOf(pacienteModule.paciente.email)
        );
      });

      paciente_conf_emailModule.getpaciente_conf_emails_activados(
        this.paciente.numero
      );
      this.paciente = pacienteModule.paciente;
      this.etiquetas_paciente = this.paciente.Pac_Etivuetify;

      hist_clinicoModule
        .gethist_clinicos_diagnosticos(this.paciente.numero)
        .then(() => {
          this.hist_clinicos_diagnosticos =
            hist_clinicoModule.hist_clinicos_diagnosticos;
        });
      hist_clinicoModule
        .gethist_clinicos_vacunas(this.paciente.numero)
        .then(() => {
          this.hist_clinicos_vacunas = hist_clinicoModule.hist_clinicos_vacunas;
        });
      hist_clinicoModule
        .gethist_clinicos_medicamentos(this.paciente.numero)
        .then(() => {
          this.hist_clinicos_medicamentos =
            hist_clinicoModule.hist_clinicos_medicamentos;
        });
      hist_clinicoModule
        .gethist_clinicos_cirujias(this.paciente.numero)
        .then(() => {
          this.hist_clinicos_cirujias =
            hist_clinicoModule.hist_clinicos_cirujias;
        });
      hist_clinicoModule
        .gethist_clinicos_otros(this.paciente.numero)
        .then(() => {
          this.hist_clinicos_otros = hist_clinicoModule.hist_clinicos_otros;
        });
    } else {
      this.paciente.notificacion_agua = true;
    }
  }

  public get ImagenPaciente() {
    return UtilsPaciente.getrutaImagen(this.paciente);
  }

  public get NombreApe() {
    return UtilsPaciente.getNameApellidos(this.paciente);
  }

  public async eliminar() {
    ssmMessageService.confirm(
      "¿Seguro que quieres eliminar el paciente " + this.paciente.nombre + "?",
      "ELIMINAR",
      async (r) => {
        if (r) {
          await pacienteModule
            .eliminarpaciente(this.paciente)
            .then(() => this.toListPaci())
            .catch(() => this.sendinfo());
        } else {
        }
      }
    );
  }
  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.aceptar().then((etis_seleccionadas: etiqueta_paciente[]) => {
          this.paciente.Pac_EtiDto = [];
          this.paciente.Pac_EtiDto = etis_seleccionadas;
          if (this.paciente.numero !== undefined) {
            pacienteModule.actualizarpaciente(this.paciente).then(() => {
              paciente_conf_emailModule.modificarpaciente_conf_emails(
                this.emails_marketing
              );
            });
          } else {
            pacienteModule.guardarpaciente(this.paciente).then(() => {
              paciente_conf_emailModule.modificarpaciente_conf_emails(
                this.emails_marketing
              );
            });
          }
          this.toListPaci();
        });
      }
    });
  }

  public only_updated() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.aceptar().then((etis_seleccionadas: etiqueta_paciente[]) => {
          this.paciente.Pac_EtiDto = [];
          this.paciente.Pac_EtiDto = etis_seleccionadas;
          if (this.paciente.numero !== undefined) {
            pacienteModule.actualizarpaciente(this.paciente).then(() => {
              paciente_conf_emailModule.modificarpaciente_conf_emails(
                this.emails_marketing
              );
            });
          } else {
            pacienteModule.guardarpaciente(this.paciente).then(() => {
              paciente_conf_emailModule.modificarpaciente_conf_emails(
                this.emails_marketing
              );
            });
          }
        });
      }
    });
    this.show_dialog_config_app = false;
  }

  public toListPaci() {
    this.$router.push({ name: RouterNames.pacienteslista });
  }
  public get emails_marketing() {
    return paciente_conf_emailModule.paciente_conf_emails;
  }
  public sendinfo() {
    ssmMessageService.toastinfo("No se ha podido eliminar al paciente");
  }
  public get pacientenuevo(): boolean {
    return this.paciente.numero === undefined;
  }

  public get Opciones_a_realizar() {
    let opts: buttonOptions[] = [];
    opts.push(
      new buttonOptions({
        id: 1,
        title: "Enviar email de acceso a la app",
        icono: "",
        EventMethod: this.send_mail_acceso_app,
      })
    );
    opts.push(
      new buttonOptions({
        id: 2,
        title: "Iniciar consulta",
        icono: "",
        EventMethod: this.iniciar_consulta,
      })
    );
    opts.push(
      new buttonOptions({
        id: 3,
        title: "Nueva cita",
        icono: "",
        EventMethod: this.añadircita,
      })
    );
    opts.push(
      new buttonOptions({
        id: 4,
        title: "Cambiar la contraseña",
        icono: "",
        EventMethod: () => {
          this.show_dialog_config_app = true;
          this.show_dialog_more_options = false;
        },
      })
    );
    return opts;
  }
  public send_mail_acceso_app() {
    pacienteModule
      .send_email_acceso_app(this.paciente)
      .then(() =>
        UtilsNotify.NotificacionSuccess(
          "Acceso a la app",
          "Se ha enviado correctamente el email."
        )
      );
    this.show_dialog_more_options = false;
  }
  public mas_opciones() {
    this.show_dialog_more_options = true;
  }

  public iniciar_consulta() {
    this.$router.push({
      name: RouterNames.entrevista,
      query: {
        id_paciente: UtilsEncript.Encriptar(this.paciente.numero.toString()),
      },
    });
  }

  public router_push_to(roter_name: string) {
    this.$router.push({
      name: roter_name,
      query: {
        id_paciente: UtilsEncript.Encriptar(this.paciente.numero.toString()),
      },
    });
  }

  public añadircita() {
    this.show_dialog_more_options = false;
    this.AddCitaShow = true;
  }

  public config_app() {
    this.show_dialog_config_app = true;
  }

  public async aceptar() {
    await etiquetaModule.getetiquetas_from_tipo(etiquetaModule.fromid);
    let etis_seleccionadas: etiqueta_paciente[] = [];
    for (let i = 0; i < etiquetaModule.etiquetas.length; i++) {
      const element = etiquetaModule.etiquetas[i];
      for (let j = 0; j < this.etiquetas_paciente.length; j++) {
        const element2 = this.etiquetas_paciente[j];
        let eti_pac: etiqueta_paciente = new etiqueta_paciente();
        eti_pac.id_paciente = this.paciente.numero;
        eti_pac.id_etiqueta = element.id;
        eti_pac.nombre_etiqueta = element.nombre;
        if (element.nombre === element2.text) {
          etis_seleccionadas.push(eti_pac);
        }
      }
    }
    return etis_seleccionadas;
  }

  public getpdfhistclinico(id_tipo: number) {
    hist_clinicoModule.getpdf_historial_clinico({
      id_tipo: id_tipo,
      id_paciente: this.paciente.numero,
    });
  }
  public send_email_histclinico(id_tipo: number) {
    hist_clinicoModule.send_email_historial_clinico({
      id_tipo: id_tipo,
      id_paciente: this.paciente.numero,
    });
  }

  public getpdfhistclinico_all_tipos() {
    hist_clinicoModule.getpdf_historial_clinico_all_tipos(this.paciente.numero);
  }
  public send_email_histclinico_all_tipos() {
    hist_clinicoModule.send_email_historial_clinico_all_tipos(
      this.paciente.numero
    );
  }
}
